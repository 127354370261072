<template>
  <ProductDetailsHeader/>
  <div style="margin-bottom:7rem">
    <InnerHeader/>
    <div class="row justify-content-center mt-5">
      <div class="col-12 col-lg-8 col-md-8 col-xl-8">
        <div class="row confirmpayBG">
          <div class="col-md-6 formobileview">
            <div class="formWizard  position-sticky" style="top: 5rem">
              <h3 class="processheading">
                Confirm & Proceed
              </h3>

              <article class="FilterAccordian headingcards" v-if="this.structuredJSON && this.structuredJSON.addOns">
                <header class="d-flex align-items-center" :class="{'border-bottom pb-2': this.structuredJSON.addOns.expanded}"  @click="this.structuredJSON.addOns.expanded = !this.structuredJSON.addOns.expanded">
                  <button class="btn p-0">
                    <img src="../../assets/images/minus.svg" class="hide-show-icon"   v-show="this.structuredJSON.addOns.expanded"/>
                    <img src="../../assets/images/plus.svg" class="hide-show-icon"   v-show="!this.structuredJSON.addOns.expanded"/>
                  </button>
                  <h6 class="question-title mb-0 mx-0">Add Ons</h6>
                </header>
                <div>
                  <transition name="fade" mode="out-in">
                    <div v-if="this.structuredJSON &&  this.structuredJSON.addOns.expanded">
                      <div class="d-flex gap-2 addonplan align-items-center pb-2" v-for="(addons, index) in this.addontoShow" :key="addons.optionSelected"  :class="{'my-3': index != this.addontoShow.length-1}" v-show="this.addontoShow.length>0">
                        <input class="form-check-input" type="checkbox" :value="addons.optionDescription" id="flexCheckDefault" checked disabled>
                        <label><h5 class="mb-0">{{  addons.optionDescription }}</h5></label>
                      </div>
                      <p  v-if="this.addontoShow?.length===0" class="pt-2 pb-1 d-block text-danger">No Addons Selected</p>
                    </div>
                  </transition>
                </div>
              </article>

              <template  v-if="this.structuredJSON">
              <article class="FilterAccordian headingcards mt-3" v-for="details in  filteredStructureJson" :key="details.id">
                <header class="d-flex justify-content-between align-items-center" :class="{'border-bottom pb-2': details.expanded}" @click="details.expanded = !details.expanded">
                  <div class="d-flex align-items-center">
                    <button class="btn p-0">
                      <img src="../../assets/images/minus.svg"  class="hide-show-icon"  v-show="details.expanded"/>
                      <img src="../../assets/images/plus.svg" class="hide-show-icon"  v-show="!details.expanded"/>
                    </button>
                    <h6 class="question-title mb-0">
                      {{ details.label }}
                    </h6>
                  </div>
                  <button class="edit-btn btn py-1 px-2" @click="editProcess($event, details.name)">
                    <img src="../../assets/images/edit.svg"  class="edit_icon"/>
                  </button>
                </header>

                <transition name="fade"  mode="out-in">
                  <div  v-if="details.expanded">
                    <template v-if="details.fieldGroups">
                      <div class="fieldgrp"  v-for="fieldFirst in Object.values(details.fieldGroups)" :key="fieldFirst.id">
                        <h6 class="headinglabels" v-if="displaySubHeading(fieldFirst)">{{ fieldFirst.label }}</h6>
                        <template v-if="fieldFirst.fields">
                          <div v-for="fieldSecond in Object.values(fieldFirst.fields)" :key="fieldSecond.id">
                            <div class="content mt-3">
                              <div class="d-flex justify-content-between mb-2" v-if="fieldSecond.input !=''">
                                <p>{{ fieldSecond.label }}</p>
                                <h5 class="mb-0" >{{ (fieldSecond.input !='' && fieldSecond.input =='0') ? 'No' : (fieldSecond.input !='' && fieldSecond.input =='1') ? 'Yes' : capitalizeFirstLetter(fieldSecond.input,fieldSecond.label) }}</h5>
                               
                              </div>
                            </div>
                          </div>
                        </template>
                        <template v-if="fieldFirst.fieldGroups">
                          <div v-for="fieldSecond in Object.values(fieldFirst.fieldGroups)" :key="fieldSecond.id">
                            <div class="content mt-3">
                              <div class="d-flex justify-content-between mb-2"  v-if="fieldSecond.input !=''">
                                <p>{{ fieldSecond.label }}</p>
                                <h5 class="mb-0" >{{ (fieldSecond.input !='' && fieldSecond.input =='0') ? 'No' : (fieldSecond.input !='' && fieldSecond.input =='1') ? 'Yes' : capitalizeFirstLetter(fieldSecond.input,fieldSecond.label) }}</h5>
                              </div>
                            </div>
                          </div>
                        </template>
                      </div>
                    </template>
                    <template v-if="details.fields">
                      <div  class="fieldgrp"  v-for="fieldFirst in Object.values(details.fields)" :key="fieldFirst.id">
                            <div class="content mt-3">
                              <div class="d-flex justify-content-between mb-2" v-if="fieldFirst.input !=''">
                                <p>{{ fieldFirst.label }}</p>
                                <h5 class="mb-0">{{ (fieldFirst.input !='' && fieldFirst.input =='0') ? 'No' : (fieldFirst.input !='' && fieldFirst.input =='1') ? 'Yes' :  capitalizeFirstLetter(fieldFirst.input,fieldFirst.label) }}</h5>
                              </div>
                            </div>
                      </div>
                    </template>
                  </div>
              </transition>
              </article>
            </template>

            </div>
          </div>
          <!--  -->
          <div class="col-md-6">
            <div style="position: sticky;top: 5rem" class="">
              <div class="headingcards mt-3" v-for="item in items" :key="item.heading">
              <h3>{{ item.heading }}</h3>
              <img class="confirmimage" :src="this.quoteResponseDetails && this.quoteResponseDetails.quote_response_data.supportingData.insCompanyLogo" alt="Bank Logo"  />
              <div class="d-flex align-items-center mt-3 justify-content-between">
                <p>{{ item.planType }}</p>
                <h4>{{ this.quoteResponseDetails && this.quoteResponseDetails.quote_response_data.insuranceAndProducts.policyType}}</h4>
              </div>
              <div class="d-flex align-items-center justify-content-between" v-if="this.quoteResponseDetails?.quote_response_data.insuranceAndProducts.policyType!=='StandaloneTP(1)'">
                <p>{{ item.coverType }}</p>
                <h4>{{ this.quoteResponseDetails && currency(this.quoteResponseDetails.quote_response_data.supportingData.insuredDeclaredValue.suggestedIDV) }}</h4>
              </div>
              <hr />
              <div class="d-flex align-items-center gap-1 specifications" v-for=" usp in this.extractUSP(this.quoteResponseDetails?.quote_response_data?.supportingData)"
              :key="usp">
              <img src="../../assets/images/checksvg.svg" width="10px" />
                 
                <p>{{ usp}}</p>
              </div>
              
              <hr />
              <div class="d-flex align-items-center mt-3 justify-content-between">
                <p>{{ item.premiumAmount }}</p>
                <h4>{{this.quoteResponseDetails &&  currency(this.quoteResponseDetails.quote_response_data.productDetails.totalPremiumDetails[0].totalPremiumValueBeforeTax) }}</h4>
              </div>
              <div class="d-flex align-items-center justify-content-between">
                <p>{{ item.GSTType }}</p>
                <h4>{{ this.quoteResponseDetails &&  currency(this.quoteResponseDetails.quote_response_data.productDetails.totalPremiumDetails[0].totalTax)}}</h4>
              </div>
              <hr />
              <div class="d-flex align-items-center justify-content-between">
                <h4>{{ item.amountTitle }}</h4>
                <h6>{{ this.quoteResponseDetails &&  currency(this.quoteResponseDetails.quote_response_data.productDetails.totalPremiumDetails[0].totalPremiumWithTax) }}</h6>
              </div>
              <div class="text-end">
                <!-- <a href="#" style="font-size: 12px;text-decoration: underline;" data-bs-toggle="modal" data-bs-target="#amountBreakup">Show Breakup</a> -->
                 <span @click="showBreakupmodal()" class="text-primary" style="font-size: 12px;text-decoration: underline;">Show Breakup</span>
              </div>
            </div>
            
            <p v-for="(usp, index) in usps" :key="index">usp{{ usp }}</p>
            <div class="row mt-3">
              <div class="col-md-6 mb-2">
                <router-link to="/process-wizard">
                  <button class="backbutton">Back</button>
                </router-link>
              </div>
              <div class="col-md-6 mb-2">
                <!-- <router-link to="/complete-quote"> -->
                 
                  <button @click="submitproposal()" class="btn commonButton mb-2">Submit</button>
                <!-- </router-link> -->
              </div>
            </div>
            </div>
          </div>
        </div>
      </div>
    </div>



  </div>
  <VarificationModal />
  <AmountBreakup v-if="this.openAmountBreakupflag" @emitClose="emitClose()"/>
  <pageLoader v-if="loadingState" :msg="loadingMessage"/>
</template>

<script>
import VarificationModal from "@/modals/varificationModal.vue";
import InnerHeader from "../header/innerHeader.vue";
import pageLoader from "../pageLoaders/pageLoader.vue";
import AmountBreakup from "@/modals/amountBreakup.vue";
import ProductDetailsHeader from "../productDetailsHeader/ProductDetailsHeader.vue";

export default {
  components: {
    VarificationModal,
    InnerHeader ,
    pageLoader,
    AmountBreakup,
    ProductDetailsHeader
  },
  methods: {
    editProcess(e, name){
      e.stopPropagation(); 
      sessionStorage.setItem(
        "currentTab",
        JSON.stringify(name)
      );
      this.$router.push({ path: '/process-wizard',  query: { process: name }})
    },
    showBreakupmodal(){
      console.log("clicked-------");
      this.openAmountBreakupflag=true;
    },
    extractUSP(obj) {
      const uspArray = [];
      for (let key in obj) {
        if (key.startsWith('usp') && obj[key] && obj[key] !== null && obj[key] !== undefined) {
          uspArray.push(obj[key]);
        }
      }
      return uspArray;
    },
    capitalizeFirstLetter(input,input2) {
      if(input2=="Date of Birth" || input2=="Policy Expiry Date" ){
        const date = new Date(input);
      if (isNaN(date.getTime())) return input; // Return original if invalid date

      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const year = date.getFullYear();

      return `${day}-${month}-${year}`;
       }
      if (typeof input !== 'string' || input === '') return input;
      return input.charAt(0).toUpperCase() + input.slice(1);
     
    },
    currency(amt) {
      const tmpAmt = parseFloat(amt).toFixed(2);
      return tmpAmt.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    submitproposal(){
      this.updateProposalStatus(11);     
    },
    updateProposalStatus(statusid)
    {
        let qData = {
          "journey_id":this.journey_id,
          "status_id": statusid
          };
        var headers = {
          // "quoteId":this.uiQuoteId,
          Authorization: "Basic " + window.btoa(process.env.VUE_APP_USERNPASS),
          "Content-Type": "application/json",
          };

          this.loadingState = true;
          this.loadingMessage = "Updating Proposal Status"
        
        this.backendApi("ProposalStatus", qData, headers)
          .then((response) => {
            this.loadingState = false;
            if(response == 'error') {
              self.apiError = true;
              
            }
           
             else if(response && response.data) {
              console.log('proposal status updated: ', response.data);
              if(statusid == 11 || statusid == 13)
              {
                if(response.data.status == "failed")
                {
                  let msg=['We are facing some issue while sending an e-mail']
                  this.$router.push({ path: '/networkalert', query: {backUrl:'proposal-details-check', msg: msg }});
                }
                else
                {
                  this.$router.push('/thank-you-RM');
                }
              }
            }
          })
          .catch((error) => {
            this.loadingState = false;
            console.log('error msg block email', error);
            
          });
    },
    emitClose(){
      this.openAmountBreakupflag = false;
    },
    getSPDetailsOneSB(man_code ) {
      let reqData = [man_code];
      var headers ={ "Authorization": `Bearer ${this.$store.state.token}` };
      this.loadingState = true;
      this.loadingMessage = "Fetching SP Details"
      this.backendApi("getSPDetailsOneSB", reqData, headers, "get")
      .then((response) => {
        this.loadingState = false;
        if(response == 'error') {
          self.apiError = true;
        } else if(response && response.data) {
          this.restructureJson();
          console.log('response sp details: ', response.data);
          if(response.data.status == "failed")
          {
            //redirection to error page and on go back button redirect to homepage
          }
          sessionStorage.setItem("sp_details_data", JSON.stringify(response.data));
        }
      })
      .catch((error) => {
        this.loadingState = false;
        console.log('error msg block spdetailOneSB API', error);
        this.$router.push({ path: '/error-page', query: {msg: "SP details not Available" }});

      });
    },
    //get ProposalDetails
    getProposalById(journey_id) {
      let qData = [this.journey_id,"Proposal Review"];
      var headers ={ "Authorization": `Bearer ${this.$store.state.token}` };
      this.loadingState = true;
      this.loadingMessage = "Fetching Proposal Details..."
      this.backendApi("getProposalDetails", qData, headers, "get")
      .then((response) => {
          this.loadingState = false;
          this.proposalDetails = response.data.data.proposal;
          sessionStorage.setItem(
            "filledData",
            JSON.stringify(this.proposalDetails.proposal_json)
          );
          sessionStorage.setItem(
            "proposal_ui_id",
            JSON.stringify(this.proposalDetails.proposal_ui_id)
          );
          //call sp details api 
          let spdetail_flag= process.env.VUE_APP_SPDETAIL_ONESB;
          if(spdetail_flag == 1)
          {
            if (sessionStorage.getItem("rm_data")){
              this.rmData = JSON.parse(sessionStorage.getItem("rm_data"));
              if( this.rmData['RoleId']!=="1"){
                this.getSPDetailsOneSB( this.proposalDetails.insurance_company_code);
              }
            }
          }
          this.quoteDetails = response.data.data.quote_request;
          this.userDetails = response.data.data.user;
          
          
          sessionStorage.setItem(
            "user_profile",
            JSON.stringify(this.userDetails)
          );
          sessionStorage.setItem(
            "quote_request",
            JSON.stringify(this.quoteDetails.quote_request_json)
          );
          
          this.quoteResponseDetails = response.data.data.quote_response;
          
          let pln = this.quoteResponseDetails.quote_response_data;
          sessionStorage.setItem("selected_plan_data", JSON.stringify(pln));
          sessionStorage.setItem(
            "selectedPlanForCheckout",
            JSON.stringify(pln)
          );
          sessionStorage.setItem(
            "journey_id",
            JSON.stringify(journey_id)
          );
          this.$store.commit("setJourneyId", journey_id);
          if (this.proposalDetails.tag_name) {
            sessionStorage.setItem(
              "filledDataTab",
              JSON.stringify(this.proposalDetails.tag_name)
            );
            sessionStorage.setItem(
              "currentTab",
              JSON.stringify(this.proposalDetails.tag_name)
            );
          }
        }) 
        .catch((error) => {
          this.loadingState = false;
          console.log("error msg block dp", error);
        });
    },
    restructureJson(){
        let addOnsObject = {
          expanded: false,
          data: this.quoteResponseDetails.quote_response_data.productDetails.motorCovers,
          addOnSelected: []
        }
        this.structuredJSON.addOns = addOnsObject;
        const otherValues = Object.values(this.proposalDetails.proposal_json.fieldGroups)
  .filter(requiredFields => requiredFields.fieldGroups !== undefined)
  .map(item => {
      return {
        ...item,
        expanded: false
      };
    });
    this.structuredJSON.otherRequiredFields = otherValues;

    // document.querySelectorAll('.form-check-input').forEach(element => element.setAttribute('checked', true));
    },
    displaySubHeading(data){
      let displayFlag = false;
      if (data.fields) {
          for (let key in data.fields) {
            if (data.fields[key].input && data.fields[key].input.trim() !== "") {
              displayFlag = true;
            }
          }
        }
        if (data.fieldGroups) {
          for (let key in data.fieldGroups) {
            if (data.fieldGroups[key].input && data.fieldGroups[key].input.trim() !== "") {
              displayFlag = true;
            }
          }
        }
        return displayFlag;
    }
  },
  mounted() {
    // this.selected_plan_data = JSON.parse(sessionStorage.getItem("selected_plan_data"));
    // this.apiReqData = JSON.parse(sessionStorage.getItem("quote_request"));
    // // this.setupData();
    this.journey_id = JSON.parse(sessionStorage.getItem("journey_id"));
    this.addontoShow=JSON.parse(sessionStorage.getItem("addontoShow"));
    this.loadingState = true;
    this.getProposalById(this.journey_id);
    console.log("this.journey_id",this.journey_id);
  },
  data() {
    return {
      loadingState: false,
      loadingMessage: "",
      structuredJSON:{},
      userDetails:{},
      quoteDetails:{},
      proposalDetails:{},
      journey_id:"",
      responseData: [],
      addontoShow:[],
      openAmountBreakupflag:false,
      quoteResponseDetails:null,
      items: [
        {
          rmData: null,
          selected_plan_data: null,
          quote_request: null,
          heading: "Plan Summary",
          BankLogo: "$SBI",
          planType: "Policy Type",
          plan: "₹ 14,560",
          coverType: "IDV Cover",
          cover: "₹13,36,452",
          nestedItems: [
            {
              checkitems: "Zero Paper Claims",
            },
            {
              checkitems: "Spot Claims Upto Rs. 20k",
              carcover: "₹ 14,560",
            },
            {
              checkitems: "28 Cashless Garages",
            },
            {
              checkitems: "Self-Video Claims",
            },
          ],
          premiumAmount: "Premium Amount",
          amount: "₹ 14,560",
          GSTType: "GST @18%",
          GST: "₹ 2620",
          amountTitle: "Total Amount",
          totalAmount: "₹ 17,180",
        },
      ],
      Addon: [
        {
          checkheadings: "Zero Paper Claims",
        },
        {
          checkheadings: "Spot Claims Upto Rs. 20k",
        },
        {
          checkheadings: "28 Cashless Garages",
        },
        {
          checkheadings: "Self-Video Claims",
        },
      ],
      Additions: [
        {
          id: 1,
          accoheading: "Owner Details",

          expanded: true,
          nestedItems: [
            {

              checkContent: "Akash Chopra",
              subcheck: "Name",
            },
            {
              
              checkContent: "+91 8888 38888",
              subcheck: "Mobile",
            },
            {
              
              checkContent: "16/04/1986",
              subcheck: "Date of Birth",
            },
            {
              
              checkContent: "AUK37878K",
              subcheck: "PAN",
            },
            {
              
              checkContent: "Male",
              subcheck: "Gender",
            },
            {
              
              addressContent: "328/330, Mangaldas Market, opposite Jumma Masjid, Mumbai, Maharashtra 400002, India",
              address: "Address",
            },
           
          ],
        },
        {
            id: 2,
          accoheading: "Vehicle Details",

          expanded: true,
          nestedItems: [
            {
              
              checkContent: "1887787377",
              subcheck: "Engine Number",
            },
            {
              
              checkContent: "299898798788",
              subcheck: "Chassis Number",
            },
            {
              
              checkContent: "Yes",
              subcheck: "Is your vehicle taken on loan?",
            },
            {
              
              checkContent: "SBI Auto Loan",
              subcheck: "Financier",
            },
           
           
          ],
        },
        {
            id: 3,
          accoheading: "Previous Policy Details",

          expanded: true,
          nestedItems: [
            
            {
                subcontent:"Own Damage Detail",
              checkContent: "SBI",
              subcheck: "Insurer",
            },
            {
              
              checkContent: "12/06/2025",
              subcheck: "Policy Expiry Date",
            },
            {
              
              checkContent: "99389898",
              subcheck: "Policy Number",
            },
        
          
           
          ],
        },
        
      ],
    };
  },
  computed: {
    resolveImagePath() {
      return (variable) => {
        // Map the SCSS variable name to its actual value
        const variableMap = {
          $SBI: "/images/state-bank-of-india-logo.svg", // Match this to your SCSS variable definition
        };
        return variableMap[variable] || "";
      };
    },
    filteredStructureJson(){
      const checkInputValues = (obj) => {
        if (obj.fields) {
          for (let key in obj.fields) {
            if (obj.fields[key].input && obj.fields[key].input.trim() !== "") {
              return true;
            }
          }
        }
        if (obj.fieldGroups) {
          for (let key in obj.fieldGroups) {
            if (checkInputValues(obj.fieldGroups[key])) {
              return true;
            }
          }
        }
        return false;
      };
      return this.structuredJSON.otherRequiredFields && this.structuredJSON.otherRequiredFields.filter(container => checkInputValues(container));
    }    
  },
 
 
};
</script>

<style scoped lang="scss">
@import "../../assets/scss/components/select-card.scss";
@import "../../assets/scss/components/multi-quotes.scss";
@import "../../assets/scss/components/form-wizard.scss";

.fade-enter-active,
.fade-leave-active{
  transition: opacity 0.3s ease-in-out;
}
.fade-enter-from, .fade-leave-to{
  opacity: 0;
}
.fade-enter-to, .fade-leave-from {
  opacity: 1;
}
</style>
