<template>
  <InnerHeaderDiy />
  <ProductDetailsHeader/>
  <div class="" style="margin:2rem 0 5rem ">
    <div class="row justify-content-center">
      <div class="col-12 col-lg-10 col-md-10 col-xl-10 confirmpayment">
        <div class="row">
          <div class="col-md-6 formobileview ">
            <div class="formWizard  position-sticky" style="top: 5rem">
              <h3 class="processheading">
                Confirm & Proceed
              </h3>

              <article class="FilterAccordian headingcards" v-if="this.structuredJSON && this.structuredJSON.addOns">
                <header class="d-flex justify-content-between align-items-center"
                  :class="{ 'border-bottom pb-2': this.structuredJSON.addOns.expanded }"
                  @click="this.structuredJSON.addOns.expanded = !this.structuredJSON.addOns.expanded">
                  <h6 class="question-title mb-0">
                    Add Ons
                  </h6>
                  <button class="btn p-0">
                    <img src="../../assets/images/minus.svg" class="plusminusicon"
                      v-show="this.structuredJSON.addOns.expanded" />

                    <img src="../../assets/images/plus.svg" class="plusminusicon"
                      v-show="!this.structuredJSON.addOns.expanded" />

                  </button>
                </header>
                <div>
                  <transition name="fade" mode="out-in">
                    <div v-if="this.structuredJSON && this.structuredJSON.addOns.expanded">
                      <div class="d-flex gap-2 addonplan align-items-center pb-2"
                        v-for="(addons, index) in this.structuredJSON.addOns.data" :key="addons.optionSelected" :class="{'my-3': index != this.structuredJSON.addOns.data.length-1}">
                        <input class="form-check-input" type="checkbox" :value="addons.optionSelected"
                          id="flexCheckDefault" checked disabled>
                        <label>
                          <h5 class="mb-0">{{ addons.optionDescription }}</h5>
                        </label>
                      </div>
                    </div>
                  </transition>
                </div>
              </article>

              <template v-if="this.structuredJSON">
                <article class="FilterAccordian headingcards mt-3" v-for="details in filteredStructureJson"
                  :key="details.id">
                  <header class="d-flex justify-content-between align-items-center"
                    :class="{ 'border-bottom pb-2': details.expanded }" @click="details.expanded = !details.expanded">
                    <h6 class="question-title mb-0">
                      {{ details.label }}
                    </h6>
                    <button class="btn p-0">
                      <img src="../../assets/images/minus.svg" class="plusminusicon" v-show="details.expanded" />

                      <img src="../../assets/images/plus.svg" class="plusminusicon" v-show="!details.expanded" />

                    </button>
                  </header>

                  <transition name="fade" mode="out-in">
                    <div v-if="details.expanded">
                      <template v-if="details.fieldGroups">
                        <div class="fieldgrp" v-for="fieldFirst in Object.values(details.fieldGroups)"
                          :key="fieldFirst.id">
                          <h6 class="headinglables" v-if="displaySubHeading(fieldFirst)">{{ fieldFirst.label }}</h6>
                          <template v-if="fieldFirst.fields">
                            <div v-for="fieldSecond in Object.values(fieldFirst.fields)" :key="fieldSecond.id">
                              <div class="content mt-3">
                                <div class="d-flex justify-content-between mb-2" v-if="fieldSecond.input != ''">
                                  <p>{{ fieldSecond.label }}</p>
                                  <h5 class="mb-0">{{ (fieldSecond.input != '' && fieldSecond.input == '0') ? 'No' :
                                    (fieldSecond.input != '' && fieldSecond.input == '1') ? 'Yes' :
                                    capitalizeFirstLetter(fieldSecond.input,fieldSecond.label) }}</h5>
                                  <!-- <template v-if="fieldSecond.value && fieldSecond.value.length>0">
                                      <select :name="fieldSecond.name" :id="fieldSecond.name">
                                        <option value="">{{ fieldSecond.label }}</option>
                                        <option :value="identify.Value" v-for="identify in fieldSecond.value" :key="identify.Value">{{ identify.Text }}</option>
                                      </select>
                                    </template> -->
                                </div>
                              </div>
                            </div>
                          </template>
                          <template v-if="fieldFirst.fieldGroups">
                            <div v-for="fieldSecond in Object.values(fieldFirst.fieldGroups)" :key="fieldSecond.id">
                              <div class="content mt-3">
                                <div class="d-flex justify-content-between mb-2" v-if="fieldSecond.input != ''">
                                  <p>{{ fieldSecond.label }}</p>
                                  <h5 class="mb-0">{{ (fieldSecond.input != '' && fieldSecond.input == '0') ? 'No' :
                                    (fieldSecond.input != '' && fieldSecond.input == '1') ? 'Yes' :
                                    capitalizeFirstLetter(fieldSecond.input,fieldSecond.label) }}</h5>
                                </div>
                              </div>
                            </div>
                          </template>
                        </div>
                      </template>
                      <template v-if="details.fields">
                        <div class="fieldgrp" v-for="fieldFirst in Object.values(details.fields)" :key="fieldFirst.id">
                          <div class="content mt-3">
                            <div class="d-flex justify-content-between mb-2" v-if="fieldFirst.input != ''">
                              <p>{{ fieldFirst.label }}</p>
                              <h5 class="mb-0">{{ (fieldFirst.input != '' && fieldFirst.input == '0') ? 'No' :
                                (fieldFirst.input != '' && fieldFirst.input == '1') ? 'Yes' :
                                capitalizeFirstLetter(fieldFirst.input,fieldFirst.label) }}</h5>
                            </div>
                          </div>
                        </div>
                      </template>
                    </div>
                  </transition>
                </article>
              </template>

            </div>
          </div>
          <div class="col-md-6">
            <div style="position: sticky;top: 5rem" class="">
              <div class="headingcards mt-3" v-for="item in items" :key="item.heading">
                <h3>{{ item.heading }}</h3>
                <img class="confirmimage"
                  :src="this.quoteResponseDetails && this.quoteResponseDetails.quote_response_data.supportingData.insCompanyLogo"
                  alt="Bank Logo" />
                <div class="d-flex align-items-center mt-3 justify-content-between">
                  <p>{{ item.planType }}</p>
                  <h4>{{this.quoteResponseDetails && this.quoteResponseDetails.quote_response_data.insuranceAndProducts.policyType }}</h4>
                </div>
                <div class="d-flex align-items-center justify-content-between" v-if="this.quoteResponseDetails?.quote_response_data.insuranceAndProducts.policyType!=='StandaloneTP(1)'">
                  <p>{{ item.coverType }}</p>
                  <h4>{{ this.quoteResponseDetails &&
                    currency(this.quoteResponseDetails.quote_response_data.supportingData.insuredDeclaredValue.suggestedIDV)
                    }}
                  </h4>
                </div>
                <hr />
                <div class="d-flex align-items-center gap-1 specifications">
                  <img src="../../assets/images/checksvg.svg" width="10px" />

                  <p>{{ this.quoteResponseDetails && this.quoteResponseDetails.quote_response_data.supportingData.usp1
                    }}</p>
                </div>
                <div class="d-flex align-items-center gap-1 specifications">
                  <img src="../../assets/images/checksvg.svg" width="10px" />

                  <p>{{ this.quoteResponseDetails && this.quoteResponseDetails.quote_response_data.supportingData.usp2
                    }}</p>
                </div>
                <hr />
                <div class="d-flex align-items-center mt-3 justify-content-between">
                  <p>{{ item.premiumAmount }}</p>
                  <h4>{{ this.quoteResponseDetails &&
                    currency(this.quoteResponseDetails.quote_response_data.productDetails.totalPremiumDetails[0].totalPremiumValueBeforeTax)
                    }}</h4>
                </div>
                <div class="d-flex align-items-center justify-content-between">
                  <p>{{ item.GSTType }}</p>
                  <h4>{{ this.quoteResponseDetails &&
                    currency(this.quoteResponseDetails.quote_response_data.productDetails.totalPremiumDetails[0].totalTax)}}
                  </h4>
                </div>
                <hr />
                <div class="d-flex align-items-center justify-content-between">
                  <h4>{{ item.amountTitle }}</h4>
                  <h6>{{ this.quoteResponseDetails &&
                    currency(this.quoteResponseDetails.quote_response_data.productDetails.totalPremiumDetails[0].totalPremiumWithTax)
                    }}</h6>
                </div>

                <div class="text-end">
                <!-- <a href="#" style="font-size: 12px;text-decoration: underline;" data-bs-toggle="modal" data-bs-target="#amountBreakup">Show Breakup</a> -->
                <span @click="showBreakupmodal()" class="text-primary" style="font-size: 12px;text-decoration: underline;cursor: pointer;">Show Breakup</span>
                <!-- <a href="#" @click.prevent="showBreakupmodal"><p>Show Breakup</p></a> -->
              </div>
              </div>
              <div class="row mt-3">
                <div class="col-md-6">
                  <!-- <router-link to="/process">
                    <button class="backbutton">Back</button>
                  </router-link> -->
                </div>
                <div class="col-md-6">
                  <!-- <router-link to="/complete-quote"> -->
                  <button class="btn commonButton mb-2" @click="openOtpVerifyModal()">Submit</button>
                  <!-- </router-link> -->
                </div>
                <div class="mt-3 errorMessage" :innerHTML="errorInProposalSubmission" />
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>



  </div>
  <div class="modal fade" id="pollingModal" tabindex="-1" aria-labelledby="pollingModalLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="pollingModalLabel">Polling Complete</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            The polling process is complete. We have received the data successfully!
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
            <button type="button" class="btn btn-primary" @click="proceedWithData" :disabled="loadingState">Proceed</button>
          </div>
        </div>
      </div>
    </div>
  <OtpVerification v-if="this.otpOpenModalFlag" @close-modal="handleOtpVerifyModal" @resend-otp="resendOtp" :time="time"
    @set-timer="updateTime" @show-error-message="setErrorMessage" />
  <VarificationModal />
  <AmountBreakup v-if="this.openAmountBreakupflag" @emitClose="emitClose()"/>
  <!-- <verifypremium v-if="this.verifypremiumflag" @emitVerifyClose="emitVerifyClose()" @openOtpModal="openOtpVerifyModal()"/> -->

  <pageLoader :msg="loadingMessage" v-if="loadingState" />
</template>

<script>
import VarificationModal from "@/modals/varificationModal.vue";
import OtpVerification from "./OtpVerification.vue";
import pageLoader from "../pageLoaders/pageLoader.vue";
import InnerHeaderDiy from "../header/innerHeaderDiy.vue";
import AmountBreakup from "@/modals/amountBreakup.vue";
import ProductDetailsHeader from "../productDetailsHeader/ProductDetailsHeader.vue";
import { getDistributer} from '@/mixins/common';
export default {
  components: {
    OtpVerification,
    VarificationModal,
    pageLoader,
    InnerHeaderDiy,
    AmountBreakup,
    ProductDetailsHeader,
  },
  mixins: [getDistributer],
  methods: {
    beforeDestroy() {
      this.disableButtons = false;
    },
    showBreakupmodal(){
      console.log("clicked-------");
      this.openAmountBreakupflag=true;
    },
    emitClose(){
      this.openAmountBreakupflag = false;
    },
    capitalizeFirstLetter(input, input2) {
      if (input2 == "Date of Birth" || input2 == "Policy Expiry Date") {
        const date = new Date(input);
        if (isNaN(date.getTime())) return input; // Return original if invalid date

        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();

        return `${day}-${month}-${year}`;
      }
      if (typeof input !== 'string' || input === '') return input;
      return input.charAt(0).toUpperCase() + input.slice(1);

    },
    currency(amt) {
      const tmpAmt = parseFloat(amt).toFixed(2);
      return tmpAmt.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    async getSPDetailsOneSB(man_code) {
      try {
        this.loadingState = true;
        this.loadingMessage = "Getting SP Details...";
        let reqData = [man_code];
        let headers = { "Authorization": `Bearer ${this.$store.state.token}` };

        const response = await this.backendApi("getSPDetailsOneSB", reqData, headers, "get");

        this.loadingState = false;

        if (response === 'error') {
          this.apiError = true;
        } else if (response && response.data) {
          this.restructureJson();
          console.log('response sp details: ', response.data);

          if (response.data.status === "failed") {
            // Handle redirection to error page and other necessary actions
            this.$router.push({ path: '/error-page', query: { msg: "SP details not Available" } });
          }

          sessionStorage.setItem("sp_details_data", JSON.stringify(response.data));
        }
      } catch (error) {
        this.loadingState = false;
        console.log('error msg block spdetailOneSB API', error);
        this.$router.push({ path: '/error-page', query: { msg: "SP details not Available" } });
      }
    },
    //get ProposalDetails
    async getProposalById(journey_id) {
      try {
        this.loadingState = true;
        this.loadingMessage = "Getting Proposal Details...";
        let qData = [journey_id, "Proposal Review"];
        let headers = { "Authorization": `Bearer ${this.$store.state.token}` };

        const response = await this.backendApi("getProposalDetails", qData, headers, "get");

        this.apiReqData=response.data.data.quote_request.quote_request_json;
        
        // console.log("request body",this.apiReqData?.personalInformation?.individualDetails[0]?.dateOfBirth)
        console.log("request body",this.apiReqData)
        
        
        sessionStorage.setItem("rm_data", JSON.stringify(response.data.data.user));
        this.proposalDetails = response.data.data.proposal;
        sessionStorage.setItem("filledData", JSON.stringify(this.proposalDetails.proposal_json));
        sessionStorage.setItem("proposal_ui_id", JSON.stringify(this.proposalDetails.proposal_ui_id));

        this.quoteDetails = response.data.data.quote_request;
        this.userDetails = response.data.data.user;

        sessionStorage.setItem("user_profile", JSON.stringify(this.userDetails));
        sessionStorage.setItem("quote_request", JSON.stringify(this.quoteDetails.quote_request_json));

        this.quoteResponseDetails = response.data.data.quote_response;
        let pln = this.quoteResponseDetails.quote_response_data;
        sessionStorage.setItem("selected_plan_data", JSON.stringify(pln));
        sessionStorage.setItem("selectedPlanForCheckout", JSON.stringify(pln));
        sessionStorage.setItem("journey_id", JSON.stringify(journey_id));
        this.$store.commit("setJourneyId", journey_id);

        if (this.proposalDetails.tag_name) {
          sessionStorage.setItem("filledDataTab", JSON.stringify(this.proposalDetails.tag_name));
          sessionStorage.setItem("currentTab", JSON.stringify(this.proposalDetails.tag_name));
        }
        let spdetail_flag = process.env.VUE_APP_SPDETAIL_ONESB;
        if (spdetail_flag == 1) {
          if (sessionStorage.getItem("rm_data")) {
            this.rmData = JSON.parse(sessionStorage.getItem("rm_data"));
            if (this.rmData['RoleId'] !== "1") {
              await this.getSPDetailsOneSB(this.proposalDetails.insurance_company_code);
            }
          }
        }
       
      } catch (error) {
        this.loadingState = false;
        console.log("error msg block dp", error);
      }
    },
    restructureJson() {
      console.log("inside restructureJson",);
      let addOnsObject = {
        expanded: false,
        data: this.quoteResponseDetails.quote_response_data.productDetails.motorCovers,
        addOnSelected: []
      }
      this.structuredJSON.addOns = addOnsObject;
      const otherValues = Object.values(this.proposalDetails.proposal_json.fieldGroups)
        .filter(requiredFields => requiredFields.fieldGroups !== undefined)
        .map(item => {
          return {
            ...item,
            expanded: false
          };
        });
      this.structuredJSON.otherRequiredFields = otherValues;

      // document.querySelectorAll('.form-check-input').forEach(element => element.setAttribute('checked', true));
    },
    openOtpVerifyModal() {
      const expiryTimeOfOtp = sessionStorage.getItem("ExpiryTime");
      const now = new Date();

      let expiryDate = null;
      if (expiryTimeOfOtp) {
        expiryDate = new Date(expiryTimeOfOtp);
      }

      if (expiryDate instanceof Date && !isNaN(expiryDate.getTime()) && now <= expiryDate) {
        sessionStorage.setItem("OtpExpiryErrorMessage", JSON.stringify({
          type: "error",
          msg: `OTP is sent, expires at ${new Date(expiryDate).toLocaleTimeString()}`
        }))
        this.otpOpenModalFlag = true;
      } else {
        this.resendOtpFromModal();
      }
    },
    resendOtpFromModal() {
      let companyCode = JSON.parse(sessionStorage.getItem('selected_plan_data'))
      this.loadingState = true,
      this.loadingMessage = "Sending OTP...";
      const reqData = {
        distributor: {
          distributorID: this.distributorID,
          agentID: "",
          agentType: "",
          salesChannel: "",
          channelType: "B2B",
          varFields: [
            {
              fieldName: "",
              fieldValue: ""
            }
          ]
        },
        insuranceCompanyCode: companyCode.insuranceAndProducts.insuranceCompanyCode,
        UITrackingRefNo: "0031b045-d539-483f-974b-b87d33ac1e19",
        OTPDetails: {
          mobileNumber: this.proposalDetails.mobile_number,
          email: this.proposalDetails.emailId
        }
      };
      const headers = { "Authorization": `Bearer ${this.$store.state.token}` };

      this.backendApi("getOtpSend", reqData, headers, "post")
        .then((response) => {
          this.loadingState = false;
          if (response === 'error') {
            this.apiError = true;
          } else if (response && response.data) {
            this.otpVerifyResponse = response.data;
            // sessionStorage.setItem("OtpExpiryErrorMessage", null);
            sessionStorage.setItem("ExpiryTime", new Date(this.otpVerifyResponse.data.ExpiryTime).toISOString());

            const expiryTimeOfOtp = sessionStorage.getItem("ExpiryTime");
            let expiryDate = null;
            if (expiryTimeOfOtp) {
              expiryDate = new Date(expiryTimeOfOtp);
            }
            sessionStorage.setItem("OtpExpiryErrorMessage", JSON.stringify({
              type: "error",
              msg: `OTP is sent, expires at ${new Date(expiryDate).toLocaleTimeString()}`
            }))
            sessionStorage.setItem('otpId', JSON.stringify(this.otpVerifyResponse.data.OTPTransactionId));
            this.otpOpenModalFlag = true;
          } else if (response.data.errors.length > 0) {
            this.errorInProposalSubmission = "";
            let ck = 1;
            response.data.errors.forEach(function (element) {
              this.errorInProposalSubmission += ck + '. ' + element.errorDisplayMessage + '<br/>';
              ck++;
            });
          }
        })
        .catch((error) => {
          this.loadingState = false;
          console.log(error);
        });
    },
    handleOtpVerifyModal() {
      this.otpOpenModalFlag = false;
    },
    resendOtp() {
      this.resendOtpFromModal();
      this.time = 30;
      this.updateTime();
    },
    updateTime() {
      if (this.timerInterval) {
        clearInterval(this.timerInterval);
      }
      this.timerInterval = setInterval(this.updateCountdown, 1000);
    },
    setErrorMessage(data) {
      let self = this;
      let ck = 1;
      self.errorInProposalSubmission = "";
        data?.errors.forEach(function (element) {
        self.errorInProposalSubmission += ck + '. ' + element.errorDisplayMessage + '<br/>';
        ck++;
      });  
      this.loadingState = false;
      this.otpOpenModalFlag = false;
    },
    updateCountdown() {
      if (this.time > 0) {
        this.time -= 1;
      } else {
        clearInterval(this.timerInterval);
      }
    },
    async getCheckPaymentDetails() {
      this.errorMsgs = "";
      try {
        this.loadingMessage = "Checking Payment Status";
        this.loadingState = true;
        const startTime = Date.now();
        const endTime = startTime + 3 * 60 * 1000;
        const reqData = [this.journey_id, "Payment Details"];
        const headers = {};
        while (Date.now() < endTime) {
          const response = await this.backendApi("getProposalWithStage", reqData, headers, "get");
          if (response.data.errors.length === 0) {
            if (response.data.data && response.data.data.payment_status && response.data.data.payment_status !== null) {
              this.applicationNo = response.data.data.proposal_id;
              sessionStorage.setItem("applicationNumber", this.applicationNo);
              this.loadingState = false;
              this.payment_status = response.data.data.payment_status;
              this.getInsurerFeedback();
              return;
            }
          }
          await new Promise(resolve => setTimeout(resolve, 1000));
        }
        const msg = ['We are facing some issue while getting the information of payment details.'];
        this.errorMsgs = msg;
        this.loadingState = false;
      } catch (error) {
        console.error("An error occurred during payment URL polling:", error);
      }
    },
    async getInsurerFeedback() {
      try {
        this.loadingMessage = "Please wait, Getting Information";
        this.loadingState = true;
        const self = this;
        self.errorMsgs = "";
        const startTime = Date.now();
        const endTime = startTime + 3 * 60 * 1000;
        const stage = "Proposal Submitted";
        const reqData = [this.journey_id, stage];
        const headers = {};
        while (Date.now() < endTime) {
          const response = await this.backendApi("getProposalWithStage", reqData, headers, "get");
          if (response.data.errors.length === 0) {
            if (response.data.data.proposal_response !== null && response.data.data.proposal_response.errors && response.data.data.proposal_response.errors.length > 0) {
              response.data.data.proposal_response.errors.forEach(function (element, index) {
                self.errorInProposalSubmission += (index + 1) + '. ' + element.errorDisplayMsg + '<br/>';
              });
              this.errorInProposalSubmission = "";
              let ck = 1;
              response.data.errors.forEach(function (element) {
                this.errorInProposalSubmission += ck + '. ' + element.errorDisplayMsg + '<br/>';
                ck++;
              });
              this.loadingState = false;
              return;
            }
            if (response.data.data.proposal_response !== null && response.data.data.proposal_response.status.code === "200") {
              if (response?.data?.data?.proposal_response?.ManufacturerTracking?.policyNumber) {
                this.policyNo = response?.data?.data?.proposal_response?.ManufacturerTracking?.policyNumber;
                this.inspectionLink = response?.data?.data?.proposal_response?.inspectionDetails.selfInspectionLink;
                const inspectionvalidTill = response?.data?.data?.proposal_response?.inspectionDetails.inspectionValidTill;
                sessionStorage.setItem("inspectionLink", this.inspectionLink);
                sessionStorage.setItem("inspectionvalidTill", inspectionvalidTill);

                sessionStorage.setItem("policyNo", this.policyNo);
              }
              if (this.payment_status.toLowerCase() === "success") {
                this.loadingState = true;
                this.getProposalStatus();
                return;
              }
              // this.$router.push('/payment');
              return;
            }
          }
          await new Promise(resolve => setTimeout(resolve, 1000));
        }
        this.errorInProposalSubmission = ['We are facing some issue while fetching the data.'];

        this.loadingState = false;
      } catch (error) {
        console.error("An error occurred during payment URL polling:", error);
      }
    },
    async getProposalStatus() {
      try {
        this.loadingMessage = "Please wait, Getting Information";
        this.loadingState = true;
        const self = this;
        self.errorMsgs = "";
        const reqData = [this.journey_id];
        const headers = {};
        const response = await this.backendApi("getPolicyStatus", reqData, headers, "get");
        if (response.data.errors && response.data.errors.length > 0) {
          response.data.errors.forEach(function (element, index) {
            self.errorMsgs += (index + 1) + '. ' + element.errorDisplayMessage + '<br/>';
          });
        } else if (response.data.data) {
          this.policyNo = response.data.data.manufacturer[0].product[0].policyDetails.policyNumber;
          sessionStorage.setItem("policyNo", this.policyNo);
          if(response.data.data.manufacturer[0].product[0].applicationStatus.applicationStatus ==
          "Policy Issued" || response.data.data.manufacturer[0].product[0].applicationStatus.applicationStatus ==
          "Scrutiny" || response.data.data.manufacturer[0].product[0].applicationStatus.applicationStatus ==
          "Proposal Submitted"  ){
            sessionStorage.setItem("applicationStatus",JSON.stringify(response.data.data.manufacturer[0].product[0].applicationStatus.applicationStatus));
          this.$router.push('/payment');
          }
          if(response.data.data.manufacturer[0].product[0].applicationStatus.applicationStatus ==
          "Inspection Pending"){
          this.$router.push('/inspection');
          }
          if(response.data.data.manufacturer[0].product[0].applicationStatus.applicationStatus ==
          "Requirements Pending"){
          this.$router.push('/pending');
          }
          

        }
        this.loadingState = false;
      } catch (error) {
        console.error("An error occurred during payment URL polling:", error);
      }
    },
    displaySubHeading(data){
      let displayFlag = false;
      if (data.fields) {
          for (let key in data.fields) {
            if (data.fields[key].input && data.fields[key].input.trim() !== "") {
              displayFlag = true;
            }
          }
        }
        if (data.fieldGroups) {
          for (let key in data.fieldGroups) {
            if (data.fieldGroups[key].input && data.fieldGroups[key].input.trim() !== "") {
              displayFlag = true;
            }
          }
        }
        return displayFlag;
    }
  },
  async mounted() {
    this.distributorID = getDistributer()["distributerID"];
    this.selected_plan_data = JSON.parse(sessionStorage.getItem("selected_plan_data"));
    this.apiReqData = JSON.parse(sessionStorage.getItem("quote_request"));
    // this.setupData()
    if (sessionStorage.getItem("journey_id")) {
      this.journey_id = JSON.parse(sessionStorage.getItem("journey_id"));
    } else if (this.$route.query.journeyId) {
      this.journey_id = JSON.parse(this.$route.query.journeyId);
    }
    sessionStorage.setItem("journey_id", JSON.stringify(this.journey_id));
    this.loadingState = true;
    await this.getProposalById(this.journey_id);
    if (this.$route.query.response) {
      // this.payResponse= this.$route.query.response;
      // let updatedRes = tmpRes.replace(/'/g, '"');
      // this.payResponse = JSON.parse(JSON.stringify(updatedRes));
      // sessionStorage.setItem("pay_response", JSON.stringify(this.payResponse));
      // console.log("pay response: ", this.payResponse);
      await this.getCheckPaymentDetails()

    }
    // setTimeout(() => {
    //   console.log(this.filteredStructureJson);
    // }, 3000);
  },
  data() {
    return {
      apiReqData:'',
      // pollapiflag:false,
      distributorID:'',
      inspectionLink:"",
      payResponse: "",
      loadingState: false,
      loadingMessage: "",
      time: 30,
      timerInterval: null,
      otpExpiryTime: "",
      journey_id: "",
      otpOpenModalFlag: false,
      otpVerifyResponse: null,
      structuredJSON: {},
      userDetails: {},
      quoteDetails: {},
      proposalDetails: {},
      responseData: [],
      errorInProposalSubmission: "",
      openAmountBreakupflag:false,
      items: [
        {
          rmData: null,
          selected_plan_data: null,
          quote_request: null,
          heading: "Plan Summary",
          BankLogo: "$SBI",
          planType: "Plan Type",
          plan: "₹ 14,560",
          coverType: "IDV Cover",
          cover: "₹13,36,452",
          nestedItems: [
            {
              checkitems: "Zero Paper Claims",
            },
            {
              checkitems: "Spot Claims Upto Rs. 20k",
              carcover: "₹ 14,560",
            },
            {
              checkitems: "28 Cashless Garages",
            },
            {
              checkitems: "Self-Video Claims",
            },
          ],
          premiumAmount: "Premium Amount",
          amount: "₹ 14,560",
          GSTType: "GST @18%",
          GST: "₹ 2620",
          amountTitle: "Total Amount",
          totalAmount: "₹ 17,180",
        },
      ],
      Addon: [
        {
          checkheadings: "Zero Paper Claims",
        },
        {
          checkheadings: "Spot Claims Upto Rs. 20k",
        },
        {
          checkheadings: "28 Cashless Garages",
        },
        {
          checkheadings: "Self-Video Claims",
        },
      ],
      Additions: [
        {
          id: 1,
          accoheading: "Owner Details",

          expanded: true,
          nestedItems: [
            {

              checkContent: "Akash Chopra",
              subcheck: "Name",
            },
            {

              checkContent: "+91 8888 38888",
              subcheck: "Mobile",
            },
            {

              checkContent: "16/04/1986",
              subcheck: "Date Of Birth",
            },
            {

              checkContent: "AUK37878K",
              subcheck: "PAN",
            },
            {

              checkContent: "Male",
              subcheck: "Gender",
            },
            {

              addressContent: "328/330, Mangaldas Market, opposite Jumma Masjid, Mumbai, Maharashtra 400002, India",
              address: "Address",
            },

          ],
        },
        {
          id: 2,
          accoheading: "Vehicle Details",

          expanded: true,
          nestedItems: [
            {

              checkContent: "1887787377",
              subcheck: "Engine Number",
            },
            {

              checkContent: "299898798788",
              subcheck: "Chassis Number",
            },
            {

              checkContent: "Yes",
              subcheck: "Is your vehicle taken on loan?",
            },
            {

              checkContent: "SBI Auto Loan",
              subcheck: "Financier",
            },


          ],
        },
        {
          id: 3,
          accoheading: "Previous Policy Details",

          expanded: true,
          nestedItems: [

            {
              subcontent: "Own Damage Detail",
              checkContent: "SBI",
              subcheck: "Insurer",
            },
            {

              checkContent: "12/06/2025",
              subcheck: "Policy Expiry Date",
            },
            {

              checkContent: "99389898",
              subcheck: "Policy Number",
            },



          ],
        },

      ],
    };
  },
  computed: {
    resolveImagePath() {
      return (variable) => {
        // Map the SCSS variable name to its actual value
        const variableMap = {
          $SBI: "/images/state-bank-of-india-logo.svg", // Match this to your SCSS variable definition
        };
        return variableMap[variable] || "";
      };
    },
    filteredStructureJson() {
      const checkInputValues = (obj) => {
        if (obj.fields) {
          for (let key in obj.fields) {
            if (obj.fields[key].input && obj.fields[key].input.trim() !== "") {
              return true;
            }
          }
        }
        if (obj.fieldGroups) {
          for (let key in obj.fieldGroups) {
            if (checkInputValues(obj.fieldGroups[key])) {
              return true;
            }
          }
        }
        return false;
      };
      return this.structuredJSON.otherRequiredFields && this.structuredJSON.otherRequiredFields.filter(container => checkInputValues(container));
    }
  },
};
</script>

<style scoped lang="scss">
@import "../../assets/scss/components/select-card.scss";
@import "../../assets/scss/components/multi-quotes.scss";
@import "../../assets/scss/components/form-wizard.scss";

.fade-enter-active,
.fade-leave-active {
  transition: all 0.2s cubic-bezier(0.215, 0.610, 0.355, 1);
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.fade-enter-to,
.fade-leave-from {
  opacity: 1;
}

.errorMessage {
  color: rgb(255, 94, 94);
  font-size: 12px;
}
</style>
