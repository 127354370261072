<template lang="">
  <div v-if="this.loading">
     <pageLoader :msg='loadingMsg'/>
  </div>
  <div class="row justify-content-center">
     <div class="col-12 col-md-10 col-lg-8 col-xl-8 col-xxl-8">
        <div class="multi-quote ">
           <div class="row position-relative justify-content-center">
              <div class="col-md-4">
                 <div>
                    <div class="headingcards mb-3 d-flex justify-content-between align-items-center">
                       <div>
                          <div class="mb-2 d-flex justify-content-between align-items-center">
                             <h6 class="mb-0">{{ motorProductType === '4W-Pvt' ? 'Car Details' : 'Bike Details' }}</h6>
                             <!-- <a href="" class="editform"  @click="openEditmodal"><img src="../../assets/images/edit.svg"  />Edit</a> -->
                             <button class="editform border-0" @click="openEditmodal">Edit</button>
                          </div>
                          <p class="mb-0 d-flex flex-wrap" > 
                             <span ><img src="../../assets/images/dot.svg" class="doticon" />{{this.userData.rtoCode}}&nbsp;&nbsp;</span>
                             <span ><img src="../../assets/images/dot.svg" class="doticon" />{{this.userData.carMake?.makeDescription}}&nbsp;&nbsp;</span>
                             <span ><img src="../../assets/images/dot.svg" class="doticon" />{{this.userData.model?.modelDesc}}&nbsp;&nbsp; </span> 
                             <span ><img src="../../assets/images/dot.svg" class="doticon" />{{this.userData.fuelType?.fuelType.toUpperCase()}}&nbsp;&nbsp;</span> 
                             <span ><img src="../../assets/images/dot.svg" class="doticon" />{{this.userData.variant?.variantDesc}}&nbsp;&nbsp; </span>
                             <span ><img src="../../assets/images/dot.svg" class="doticon" />{{ formatDate(this.userData.RegDate)}} </span> 
                          </p>
                       </div>
                    </div>
                    <div class="filter" v-if="screenWidth < 768" @click="toggleContent">
                       <img src="../../assets/images/filtericon.svg"   />
                       <p class="filter-header mb-2">Sort & Filter</p>
                       <!-- <div class="counter  mb-2">{{this.quoteCategory==='Roll-Over' ? 3:2}}</div> -->
                    </div>
                 </div>
                 <div class="formobilecotent" v-if="screenWidth >= 768 || showContent">
                    <div class="headingcards mt-" v-if="!this.idvVisibilityFlag">
                       <div class="d-flex gap-2 align-items-center">
                          <h6 class="mb-0">IDV</h6>
                          <span class="cardprice">&#8377;{{this.suggestedIDV != "" ?  convertToThousandsWithKorL(this.suggestedIDV) : 0}}</span>
                       </div>
                       <div class="container mt-2 p-0">
                          <span>&#8377;{{this.convertToThousandsWithKorL(this.minIDV)}}</span>
                          <span v-if="this.insuredDeclaredValue != 0">&#8377;{{this.convertToThousandsWithKorL(this.insuredDeclaredValue)}}</span>
                          <span>&#8377;{{this.convertToThousandsWithKorL(this.maxIDV)}}</span>
                       </div>
                       <input
                          class="slider"
                          type="range"
                          id="priceRange"
                          v-model="this.selectedIDV"
                          :min="this.minIDV"
                          :max="this.maxIDV"
                          step="1"
                          @input="handleIdvSelection(this.selectedIDV)"
                          />
                       <div class="d-flex gap-4 mt-3 justify-content-end" v-if="this.selectedIDV > 0">
                          <button @click="cleareIDV()" class="btn backbutton" style="width: fit-content">
                          Cancel
                          </button>
                          <button class="btn ApplyButton" @click="applyIDV()">Apply</button>
                       </div>
                    </div>
                    <div class="p-3 headingcards"  v-else>
                      <div class="d-flex align-items-center gap-2 mb-2">
                        <h6 class="mb-0">IDV</h6>
                        <SkeletonLoader
                            width="25%"
                            height="10"
                            class="skeletonloader" style="border-radius:50px"
                        />
                      </div>
                      <div class="d-flex justify-content-between align-items-center mb-2">
                        <SkeletonLoader
                            width="10%"
                            height="10"
                            class="skeletonloader m-0" style="border-radius:50px"
                        />
                        <SkeletonLoader
                            width="10%"
                            height="10"
                            class="skeletonloader m-0" style="border-radius:50px"
                        />
                      </div>
                      <SkeletonLoader
                            width="100%"
                            height="10"
                            class="skeletonloader mb-4" style="border-radius:50px"
                        />
                        <div class="d-flex justify-content-end align-items-center gap-2">
                          <SkeletonLoader
                            width="40%"
                            height="30"
                            class="skeletonloader m-0" style="border-radius:50px"
                        />
                          <SkeletonLoader
                            width="40%"
                            height="30"
                            class="skeletonloader m-0" style="border-radius:50px"
                        />
                        </div>
                    </div>
                    <FilterAccordian :title="this.title" :info="this.info"  @update:selected-item="handleSelectedItem" v-if="this.quoteCategory==='Roll-Over'"/>
                    <article v-if="this.policyTypes.length != 0 "
                       class="FilterAccordian headingcards mt-3"
                       :key="this.title"
                       >
                       <header class="d-flex justify-content-between align-items-center">
                          <h6 @click="this.policyExpand = !this.policyExpand" class="question-title mb-0">
                             Policy Types
                          </h6>
                          <button @click="this.policyExpand = !this.policyExpand" class="btn p-0">
                          <img src="../../assets/images/minus.svg" class="plusminusicon"  v-show="this.policyExpand" />
                          <img src="../../assets/images/plus.svg" class="plusminusicon"  v-show="!this.policyExpand" />
                          </button>
                       </header>
                       <transition mode="out-in" name="fadeAndSlide">
                        <div v-if="this.policyExpand">
                            <div>
                              <div
                                  :style="contentStyle"
                                  class="content"
                                  >
                                  <div v-for="(item,index) in this.policyTypes"
                                    :key="index" class="form-check mt-2">
                                    <input
                                        class="form-check-input"
                                        type="checkbox"
                                        :id="'checkbox_' + item"
                                        :value="item"
                                        v-model="policyTypesSelected"       
                                        />
                                    <label
                                        :for="'checkbox_' + item"
                                        class="form-check-label"
                                        >
                                        <div class="d-flex align-items-center gap-2">
                                          <h5 class="mb-0">{{ item }}</h5>
                                        </div>
                                    </label>
                                  </div>
                              </div>
                              <div class="d-flex gap-4 mt-3 justify-content-end">
                                  <button  @click="clearPolicyFilter()"  class="btn backbutton" style="width: fit-content">
                                  Cancel
                                  </button>
                                  <!-- :disabled="!this.pollapiflag" -->
                                  <button class="btn ApplyButton"  @click="applyPolicyType()" >Apply</button>
                                  <!-- :disabled="!this.pollapiflag" -->
                              </div>
                            </div>
                        </div>
                      </transition>
                    </article>
                 </div>
              </div>
              <div class="col-md-8 formobileonly pl-0">
                 <h6 class="commonclass">{{this.responseData?.length}} Quotes Found</h6>
                 <div class="d-grid gap-3">
                    <!-- Card Start -->
                    <div
                       class="headingcards row justify-content-between"
                       v-for="item in this.responseData"
                       :key="item?.supportingData?.insCompanyLogo"
                       >
                       <div class="col-md-8 col-8">
                          <div class="img-container">
                             <img
                                :src="resolveImagePath(item?.supportingData?.insCompanyLogo)"
                                alt="Bank Logo" class="quote-logo" draggable="false"
                                />
                          </div>
                          <div
                             class="d-flex align-items-center flex-wrap justify-content-start gap-1 mt-1"
                             >
                             <p>Policy type - </p>
                             <span>{{item.insuranceAndProducts.policyType}}</span>
                          </div>
                          <div class="d-flex flex-wrap gap-2 mt-2">
                             <div
                                class="d-flex align-items-center gap-1 specifications"
                                v-for=" usp in this.extractUSP(item?.supportingData)"
                                :key="usp"
                                >
                                <img src="../../assets/images/checksvg.svg" class="commonicon"  />
                                <p>{{ usp }}</p>
                             </div>
                          </div>
                       </div>
                       <div class="col-md-4 col-4">
                          <button
                             class="clickbuttons d-flex gap-3 align-items-center"
                             style="margin: auto"
                             @click="this.getPolicydetails(item)"
                             > &#8377;&nbsp{{currency(item.productDetails.totalPremiumDetails[0].totalPremiumWithTax)}}
                          <img src="../../assets/images/multiquotearrow.svg" class="commonicon"   />
                          </button>
                          <div
                             class="d-flex align-items-center flex-wrap justify-content-center gap-1 mt-1"
                             >
                             <p>IDV Cover</p>
                             <!-- <span>{{!item.supportingData.insuredDeclaredValue.suggestedIDV ? 0 : this.currency(item.supportingData.insuredDeclaredValue.suggestedIDV)}}</span> -->
                             <span>{{item.insuranceAndProducts.policyType=='StandaloneTP(1)' ? 0 : !item.supportingData.insuredDeclaredValue.suggestedIDV ? 0 : this.currency(item.supportingData.insuredDeclaredValue.suggestedIDV)}}</span>
                          </div>
                          <div class="text-center mt-2">
                             <a :href="item.supportingData.Messages.brochureLink" target="_blank" data-bs-toggle="tooltip" data-bs-placement="top" title="Download Brochure">
                             <img src="../../assets/images/pdf.svg" class="pdficon"  />
                             </a>
                          </div>
                       </div>
                    </div>
                    <!-- Card End -->
                    <!-- data not found -->
                    <div v-if="displayErrorMessages">
                      <p class="mt-3 errorMessage" :innerHTML="displayErrorMessages" />
                    </div>
                    <div class="data-not-found" v-if="this.responseData.length==0 && this.pollapiflag && !this.loading2">
                       <div v-if="!this.loading2">
                          <img src="/images/data-not-found.gif" />
                          <p>No products available</p>
                       </div>
                    </div>
                    <!-- <MultiquoteLoader/> -->
                    <div v-if="this.loading2" class="text-center w-100">
                      <img src="/images/multiquoteloader.gif" class="mt-5" style="width:60%" />
                      <p class="loading-quote">Loading more quotes for you. This may take few minutes</p>
                       <!-- <MultiquoteLoader/> -->
                       <!-- <pageLoader :msg='loadingMsg'/> -->
                    </div>
                     <!-- -------------------------Showing the Loading Message--------------------- -->
                     <!-- <span class="loading_message" v-if="this.loading2">Please wait a moment while we get more quotes. It will take a few minutes.</span> -->
                    <!-- -------------------------Showing the Loading Message--------------------- -->
                 </div>
              </div>
           </div>
        </div>
     </div>
  </div>
  <editDetailsModal v-if="showeditmodal" @close-Editmodal="closeEditmodal"></editDetailsModal>
</template>
<script>
// import RangeSlider from "@/components/multiQuote/priceRangeSlider.vue";
import FilterAccordian from "@/components/multiQuote/filterAccordian.vue";
import editDetailsModal from "@/modals/editDetailsModal.vue"
// import loading from "../pageLoaders/pageLoader.vue";
import { getLazyLoadingConfig } from '@/mixins/common';
import pageLoader from "../pageLoaders/pageLoader.vue";
import alasql from 'alasql';
import MultiquoteLoader from "../pageLoaders/multiquoteLoader.vue";
import { SkeletonLoader } from "vue3-loading-skeleton";
import { getDistributer} from '@/mixins/common';
export default {
  components: {
    // RangeSlider,
    FilterAccordian,
    editDetailsModal,
    pageLoader,
    MultiquoteLoader,
    SkeletonLoader
  },
  mixins: [getDistributer],
  data() {
    return {
      distributorID:'',
      policyExpand: true,
      title1: "Policy Types",
      policyTypes: [],
      policyTypesSelected: [],
      onMountFlag: true,
      insuredDeclaredValue: 0,
      insuredDeclaredValues: [],
      selectedItem: null,
      title: "NCB",
      info: "Is claim taken in previous year?",
      selectedIDV: 0,
      maxIDV: 0,
      minIDV: 0,
      suggestedIDV: 0,
      productCodes: [],
      apiCallinginterval: '',
      loadingMsg: "Please wait while we are fetching the data",
      loading: true,
      loading2: true,
      responseData: [],
      responseData1: [],
      requestId: "",
      requestBody: {},
      pollapiflag: true,
      screenWidth: window.innerWidth,
      showContent: false,
      idvVisibilityFlag: false,
      refetchIDVonClick: false,
      userData: {},
      rmData: {},
      quoteCategory: '',
      previousPolicyTpStartDatenew: '',
      previousPolicyTpExpiryDatenew: '',
      breakTheLoop: false,
      displayErrorMessages: "",
      showeditmodal:false,

    };
  },
  mounted() {
    this.distributorID = getDistributer()["distributerID"];
    this.loading2 = true;
    sessionStorage.removeItem("rtoCode")
    //get Interval 
    this.apiCallinginterval = parseInt(getLazyLoadingConfig()['inerval']);
    this.quoteCategory = this.$store.state.quoteCategory;
    //get Multi quote
    // Update screenWidth on resize
    window.addEventListener("resize", this.updateScreenWidth);
    if (sessionStorage.getItem("userData")) {
      this.userData = JSON.parse(sessionStorage.getItem("userData"));
      if (this.userData.TpDate) {
        this.previousPolicyTpStartDatenew = this.userData.TpDate === "" ? this.calculatePreviousPolicyTpStartDate(this.userData.OdDate) : this.calculatePreviousPolicyTpStartDate(this.userData.TpDate);
        this.previousPolicyTpExpiryDatenew = this.userData.TpDate === "" ? this.previousPolicyTpExpiryDate(this.userData.OdDate) : this.userData.TpDate;
      }
      else {
        this.previousPolicyTpStartDatenew = null,
          this.previousPolicyTpExpiryDatenew = null
      }
    }
    this.rmData = JSON.parse(sessionStorage.getItem("rm_data"));
    this.getMultiQuote();
    this.idvVisibilityFlag = true;
  },
  beforeUnmount() {
    // Remove event listener on component destroy
    window.removeEventListener("resize", this.updateScreenWidth);
  },
  methods: {
    openEditmodal(){
   this.showeditmodal=true
    },
    closeEditmodal(){
   this.showeditmodal=false
    },
    // Reload IDV slider Container
    reloadIDVcontainer(){
      this.idvVisibilityFlag = true;
      setTimeout(() => {
        this.idvVisibilityFlag = false;
      }, 500);
    },
    // Date fromatting to DD-MM-YYYY Fromat
    formatDate(date) {
      const dateObj = new Date(date);
      const day = String(dateObj.getDate()).padStart(2, '0');
      const month = String(dateObj.getMonth() + 1).padStart(2, '0');
      const year = dateObj.getFullYear();
      const formattedDate = `${day}-${month}-${year}`;
      return formattedDate;
    },
    //Clear Policy Filter
    clearPolicyFilter() {
      this.responseData = this.responseData1;
      this.policyTypesSelected = this.policyTypes;
    },

    //Apply Policy Type Filter
    applyPolicyType() {
      this.responseData = this.responseData1.filter(item => this.policyTypesSelected.includes(item.insuranceAndProducts.policyType));
    },
    //Cleare IDV
    cleareIDV() {
      this.selectedIDV = this.suggestedIDV;
      this.insuredDeclaredValue = this.selectedIDV;
    },
    //Apply IDV
    applyIDV() {
      this.insuredDeclaredValue = this.selectedIDV;
      this.breakTheLoop = true;
      this.pollapiflag = false;
      this.getMultiQuote();
      this.idvVisibilityFlag = true;
      this.refetchIDVonClick = true;
      this.loading2 = true;
    },
    //Handel Selected Item
    handleSelectedItem(value) {
      this.userData = JSON.parse(sessionStorage.getItem("userData"));
      this.selectedItem = value; // Update the parent component's data
      this.pollapiflag = false;
      this.getMultiQuote();
    },
    resolveImagePath(variable) {
      return variable || "";
    },
    // Get Selected IDV all API
    // getSelectedIDV() {
    //   //product.idvDetails.insuredDeclaredValue
    //   this.insuredDeclaredValue = this.selectedIDV
    //   // this.minIDV = this.selectedIDV;
    // },
    // Handle IDV Selection on Range
    handleIdvSelection(value) {
      this.insuredDeclaredValue = value;
    },
    //Convert in Thousand with K
    convertToThousandsWithKorL(number) {
      const number1 = parseFloat(number);
      if (number1 >= 100000) {
        // 1 lakh (100,000) or more
        return (number1 / 100000).toFixed(1) + 'L';
      } else if (number1 >= 1000) {
        // 1 thousand (1,000) or more
        return (number1 / 1000).toFixed(1) + 'k';
      }
      return number1.toString();
    },

    //Extract USP Array
    extractUSP(obj) {
      const uspArray = [];
      for (let key in obj) {
        if (key.startsWith('usp') && obj[key] && obj[key] !== null && obj[key] !== undefined) {
          uspArray.push(obj[key]);
        }
      }
      return uspArray;
    },
    getPolicydetails(data) {
      this.pollapiflag = true;
      sessionStorage.setItem("selected_plan_data", JSON.stringify(data))
      this.$router.push("/policy-details");
    },
    //Get Polling Data
    pollData() {
      // this.disableButtons = true;
        if (this.pollapiflag == true) {
          this.getMotorProduct();
        } else {
          this.loading2 = false;
          if (this.responseData?.length == 0) {
            this.erMsg = 'No products found as per your selected filter. Please change the filters.';
          }
          this.beforeDestroy();
        }
    },
    //Cleare Interval
    beforeDestroy() {
      this.disableButtons = false;
    },
    //Convert Currency 
    currency(amt) {
      const tmpAmt = parseFloat(amt).toFixed(2);
      return tmpAmt.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    sleep(ms){
      return new Promise(resolve  => setTimeout(resolve, ms))
    },
    //Polling API
    async getMotorProduct() {
      this.loading2 = true;
      // this.suggestedIDV = 0;
      let headers = { "Authorization": `Bearer ${this.$store.state.token}` };
      let reqData = [this.requestId];
      const startTime = Date.now();
      const maxPollingTime = 3 * 60 * 1000;
      while (this.pollapiflag && (Date.now() - startTime) < maxPollingTime) {
        try {
          if(this.$route.path !== "/policy-quote"){
            this.pollapiflag = false;
            break;
          }
          // if(this.breakTheLoop){
          //   break;
          // }
          console.log("Inside Get Motor Product misssing")
          const response = await this.backendApi("quotationPoll", reqData, headers, "get");
          if (response?.data?.data?.quote?.length > 0) {
            const quotes = response?.data?.data?.quote;
            if(this.refetchIDVonClick){
              this.idvVisibilityFlag = true;
              for (let i = 0; i < quotes?.length; i++) {
                const suggestedIDV = quotes[i]?.supportingData?.insuredDeclaredValue?.suggestedIDV;
                if (suggestedIDV) {
                  this.onMountFlag = false;
                  this.idvVisibilityFlag = false;
                  this.reloadIDVcontainer();
                  this.refetchIDVonClick = false;
                  break;
                }
                if (suggestedIDV==0 && quotes.length==1) {
                  this.suggestedIDV = suggestedIDV;
                  this.insuredDeclaredValue = suggestedIDV;
                  this.selectedIDV = suggestedIDV;
                  this.onMountFlag = false;
                  this.idvVisibilityFlag = false;
                  this.reloadIDVcontainer();
                  this.refetchIDVonClick = false;
                  break;
                }
              }
            }
            if (this.onMountFlag) {
              for (let j = 0; j < quotes?.length; j++) {
                const suggestedIDV = quotes[j]?.supportingData?.insuredDeclaredValue?.suggestedIDV;
                if (suggestedIDV) {
                  this.suggestedIDV = suggestedIDV;
                  this.insuredDeclaredValue = suggestedIDV;
                  this.selectedIDV = suggestedIDV;
                  this.onMountFlag = false;
                  this.idvVisibilityFlag = false;
                  this.reloadIDVcontainer();
                  this.refetchIDVonClick = false;
                  break;
                }
                if (suggestedIDV==0 && quotes.length==1) {
                  this.suggestedIDV = suggestedIDV;
                  this.insuredDeclaredValue = suggestedIDV;
                  this.selectedIDV = suggestedIDV;
                  this.onMountFlag = false;
                  this.idvVisibilityFlag = false;
                  this.reloadIDVcontainer();
                  this.refetchIDVonClick = false;
                  break;
                }
              }
            }
            response?.data?.data?.quote?.forEach((ele) => {
              if (!this.productCodes.includes(ele.insuranceAndProducts.productCode)) {
                this.productCodes.push(ele.insuranceAndProducts.productCode)
                this.responseData.push(ele)
                this.responseData1.push(ele)
              }
            });
          }
          const values = this.responseData.map(quote => quote.supportingData.insuredDeclaredValue);
          const values1 = values.filter(obj => Object.values(obj).some(value => value != null ? value.toString().trim() != "" : 0))
          this.insuredDeclaredValues = values1.map(obj => Object.fromEntries(Object.entries(obj).map(([key, value]) => [key, Number(value)])))
          // AlaSQL query to find the minimum maximumIDV
          const maxIDV1 = alasql('SELECT MAX(maximumIDV) AS minMaxIDV FROM ?', [this.insuredDeclaredValues]);
          // this.maxIDV = this.convertToThousandsWithKorL(maxIDV1[0].minMaxIDV);
          this.maxIDV = isNaN(maxIDV1[0].minMaxIDV) ? 0 : parseInt(maxIDV1[0].minMaxIDV);
          const minIDV1 = alasql('SELECT MIN(minimumIDV) AS minimumIDV FROM ?', [this.insuredDeclaredValues]);
          // this.minIDV =this.convertToThousandsWithKorL(minIDV1[0].minimumIDV);
          this.minIDV = isNaN(minIDV1[0].minimumIDV) ? 0 : parseInt(minIDV1[0].minimumIDV);
          // Extract policyType values
          const policyTypes1 = this.responseData.map(item => item.insuranceAndProducts.policyType);
          // Get unique policyType values
          this.policyTypes = [...new Set(policyTypes1)];
          this.policyTypesSelected = JSON.parse(JSON.stringify(this.policyTypes))
          // Stop Polling
          if (response.data.data.isPollComplete == true) {
            this.loading2 = false;
            this.pollapiflag = false;
            this.reloadIDVcontainer();
            this.beforeDestroy();
            break;
          }
          if (response.data.errors.length > 0) {
            if (response.data.errors[0].errorIdentifier == "INVALID_REQUEST_ID") {
              this.pollapiflag = false;
              break;
            }
          }
          await this.sleep(1000);
        } catch (error) {
          console.log('error msg block', error);
          this.loading = false;
        }
      };
      if ((Date.now() - startTime) >= maxPollingTime) {
        this.pollapiflag = false;
        this.loading2 = false;
        this.loading = false;
      }
    },
    //Get Multi quote Data
    getMultiQuote() {
      var self = this;
      self.loading2 = true;
      self.idvVisibilityFlag = true;
      this.requestBody =
      {
        "typeOfQuote": "Multi-Quote",
        "quoteCategory": this.quoteCategory,
        "alternateFreqRequired": "No",
        "outOfBoundConfig": "No",
        "additionalSetup": {
          "currency": "",
          "userGeoLocation": {
            "latitude": null,
            "longitude": null
          },
          "userCountry": null,
          "userRegion": null,
          "userLanguage": null,
          "userIP": null,
          "userAgent": null
        },
        "distributor": {
          "distributorID": this.distributorID,
          "salesChannel": "Online",
          "agentID": "FSC0123786",
          "channelType": this.rmData.RoleId !== "1" ? "B2B" : "B2C",
          "varFields": [
            {
              "fieldName": "string",
              "fieldValue": "string"
            }
          ]
        },
        "personalInformation": {
          "individualDetails": [
            {
              "memberType": "Proposer",
              "memberSequenceNumber": 1,
              "nomineeRelWithInsured": null,
              "title": null,
              "firstName": null,
              "middleName": null,
              "lastName": null,
              "gender": "Male",
              "dateOfBirth": null,
              "age": null,
              "maritalStatus": null,
              "qualification": "Graduate",
              "occupation": "Salaried",
              "annualIncome": null,
              "email": "abcxyz@gmail.com",
              "mobileNumber": parseInt(this.userData.mobileNumber),
              "zipCode": this.userData.pinCode,
              "state": null,
              "city": null,
              "address1": null,
              "address2": null,
              "address3": null,
              "smoker": false,
              "residentStatus": null,
              "ownerBelongsToOrganizationFlag": null,
              "memberGST": null,
              "drivingLicenseNumber": null,
              "drivingExperienceYears": null,
              "varFields": [
                {
                  "fieldName": "string",
                  "fieldValue": "string"
                }
              ]
            }
          ]
        },
        "product": {
          "productType": "motor",
          "motorProductType": this.motorProductType,
          "insuranceAndProducts": [
            {
              "insuranceCompanyCode": "",
              "policyType": "",
              "productCode": [
                ""
              ]
            }
          ],
          "policyTerm": 1,
          "policyTermUnit": null,
          "premiumPaymentOption": null,
          "premiumPaymentTerm": null,
          "premiumPaymentFrequency": null,
          "planOption": {
            "planId": null
          },
          "registrationDetails": {
            "registrationNumber": this.userData.vehicleNumber ? this.userData.vehicleNumber : "New",
            "registrationDate": this.userData.RegDate,
            "registrationYear": null,
            "registrationMonth": null,
            "registrationDay": null,
            "manufacturingMonth": null,
            "manufacturingYear": (this.userData.MfgYear).toString(),
            "dateOfPurchase": this.userData.RegDate,
            "autoMembershipFlag": null,
            "autoMembershipNumber": null,
            "pucDeclaration": null,
            "pucNumber": null,
            "pucExpiryDate": null
          },
          "rtoLocationCode": this.userData.rtoCode,
          "vehicleDetails": {
            "vehicleUniqueCode": this.userData.vehicleKey,
            "vehicleMakeCode": this.userData.carMake.makeCode,
            "vehicleModelCode": this.userData.model.modelCode,
            "vehicleVariantCode": this.userData.variant.variantCode,
            "vehicleFuelType": this.userData.fuelType.fuelType,
            "vehicleSeatingCapacity": null,
            "vehicleCubicCapacity": null,
            "vehicleColor": null,
            "vehicleEngineNumber": null,
            "vehicleChassisNumber": null,
            "vehicleEnergyType": null,
            "vehicleWheelsChairAccess": null,
            "vehicleEmissionStandard": null,
            "vehicleWheelsCount": null,
            "vehicleCargoVolume": null
          },
          "idvDetails": {
            "insuredDeclaredValue": this.insuredDeclaredValue,
            "sideCarInsuredDeclaredValue": null
          },
          "ncbDetails": {
            "claimLastYearFlag": this.userData.previous_year_claim,
            "numberOfClaims": this.userData.previous_year_claim ? 1 : 0,
            "ncbPercentageLastYear": parseInt(this.userData.previous_year_claim ? 0 : this.userData.applicablePercentage)
          },
          "previousPolicyDetails": {
            "previousCoverageType": "",
            "IsPreviousPolicyExpired": this.userData.OdDate ? (this.userData.OdDate < this.currentDate ? true : false) : null,
            "previousPolicyEndDate": this.userData.OdDate === "" ? null : this.userData.OdDate,
            "previousPolicyStartDate": this.userData.OdDate === "" ? null : this.calculatePreviousPolicyStartDate(this.userData.OdDate),
            "previousPolicyInsurer": null,
            "previousPolicyNumber": null,
            "previousPolicyOdStartDate": this.userData.OdDate === "" ? null : this.calculatePreviousPolicyStartDate(this.userData.OdDate),
            "previousPolicyOdExpiryDate": this.userData.OdDate === "" ? null : this.userData.OdDate,
            "previousPolicyOdInsurer": null,
            "previousPolicyOdNumber": null,
            "previousPolicyTpStartDate": this.previousPolicyTpStartDatenew,
            "previousPolicyTpExpiryDate": this.previousPolicyTpExpiryDatenew,
            "previousPolicyTpInsurer": null,
            "previousPolicyTpNumber": null
          },
          "motorCovers": [
            {
              "optionSelected": "",
              "optionValue": [
                {
                  "optionValueDescription": "addOnSumAssured",
                  "optionValueInput": "100"
                }
              ],
              "varFields": [
                {
                  "fieldName": "",
                  "fieldValue": ""
                }
              ]
            }
          ]
        }
      }
      sessionStorage.setItem("quote_request", JSON.stringify(this.requestBody));
      this.backendApi("quotationApi", this.requestBody, { "Authorization": `Bearer ${this.$store.state.token}` }, "post")
        .then((response) => {
          if (response.data.errors.length > 0) {
            this.loading = false;
            if (response.data.errors[0].errorDisplayMessage != "") {
              for (let i = 0; i < response.data.errors.length; i++) {
                let ck = 1;
                this.displayErrorMessages += ck + '. ' + response.data.errors[i]["errorMessage"] + '<br/>';
                ck++;
                this.loading2 = false;
                this.idvVisibilityFlag = false;
              }
            }
          } else if (response && response.data) {
            this.requestId = response.data.reqId;
            sessionStorage.setItem("journey_id", JSON.stringify(response.data.data.journey_id));
            this.loading = false;
            if (response?.data?.data?.quote?.length > 0) {
              // this.suggestedIDV = response?.data?.data?.quote[0]?.supportingData?.insuredDeclaredValue?.suggestedIDV;
              // this.insuredDeclaredValue = this.suggestedIDV;
              response?.data?.data?.quote?.forEach((ele) => {

                if (!this.productCodes.includes(ele.insuranceAndProducts.productCode)) {
                  this.productCodes.push(ele.insuranceAndProducts.productCode)
                  this.responseData.push(ele)

                }
              });
            }
            // const insuredDeclaredValues = this.responseData.map(quote => quote.supportingData.insuredDeclaredValue);
            // // AlaSQL query to find the minimum maximumIDV
            // const maxIDV1 = alasql('SELECT MAX(maximumIDV) AS minMaxIDV FROM ?', [insuredDeclaredValues]);
            // // this.maxIDV = this.convertToThousandsWithKorL(maxIDV1[0].minMaxIDV);
            // this.maxIDV = isNaN(maxIDV1[0].minMaxIDV) ? 0 : parseInt(maxIDV1[0].minMaxIDV);
            // const minIDV1 = alasql('SELECT MIN(minimumIDV) AS minimumIDV FROM ?', [insuredDeclaredValues]);
            // // this.minIDV =this.convertToThousandsWithKorL(minIDV1[0].minimumIDV);
            // this.minIDV = isNaN(minIDV1[0].minimumIDV) ? 0 : parseInt(minIDV1[0].minimumIDV);
            if (response?.data?.errors?.length == 0) {
              this.responseData = [];
              this.productCodes = [];
              setTimeout(function () {
                self.pollapiflag = true;
                self.pollData();
              }, 1000);
            }
            else {
              this.loading2 = false;
              if (this.responseData.length == 0) {
                this.erMsg = 'No products found as per your selected filter. Please change the filters.';
                this.beforeDestroy();
              }
            }
          }

        }).catch((error) => {
          console.log('error msg block dp', error);
          const msg = "Service is Unavailable, Please try again later.";
          this.$router.push({ path: '/error-page', query: { backUrl: 'form-Wizard', msg: msg } });
        })
    },
    updateScreenWidth() {
      this.screenWidth = window.innerWidth;
    },
    toggleContent() {
      this.showContent = !this.showContent;
    },
    calculatePreviousPolicyStartDate(odDateString) {
      let odDate = new Date(odDateString);
      odDate.setFullYear(odDate.getFullYear() - 1);
      odDate.setDate(odDate.getDate() + 1);
      let previousPolicyStartDate = odDate.toISOString().split('T')[0];
      return previousPolicyStartDate;
    },
    previousPolicyTpExpiryDate(odDateString) {
      let odDate = new Date(odDateString);
      odDate.setFullYear(odDate.getFullYear() + 1);
      odDate.setDate(odDate.getDate() - 1);
      let previousPolicyStartDate = odDate.toISOString().split('T')[0];
      return previousPolicyStartDate;
    },
    calculatePreviousPolicyTpStartDate(tpDateString) {
      if (tpDateString) {
        let tpDate = new Date(tpDateString);
        if (this.motorProductType == "2W-Pvt") {
          tpDate.setFullYear(tpDate.getFullYear() - 5);
        }
        else
          tpDate.setFullYear(tpDate.getFullYear() - 3);
        tpDate.setDate(tpDate.getDate() + 1);
        let previousPolicyTpStartDate = tpDate?.toISOString().split('T')[0];
        return previousPolicyTpStartDate;
      }
    }
  },
  computed: {
    currentDate() {
      const today = new Date();
      const yyyy = today.getFullYear();
      const mm = String(today.getMonth() + 1).padStart(2, '0'); // Months are zero-based
      const dd = String(today.getDate()).padStart(2, '0');
      return `${yyyy}-${mm}-${dd}`;
    },
  },
};
</script>
<style scoped lang="scss">
@import "../../assets/scss/components/multi-quotes.scss";
@import "../../assets/scss/components/filters.scss";
@import "../../assets/scss/components/select-card.scss";

.container {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}
.fadeAndSlide-enter-from,
.fadeAndSlide-leave-to{
  opacity: 0;
}
.fadeAndSlide-enter-active,
.fadeAndSlide-leave-active{
  transition: opacity 0.3s ease-in-out;
}

.price::before {
  content: '\20B9';
  margin-right: 5px;
}
</style>
