<template lang="">
  <ProductDetailsHeader/>
    <div class="thank-you" >
      <div class="text-center">
        <img src="/images/successGif.gif" class="m-auto" />
        <h4 class=" text-center text-success">Payment Success</h4>
        <h4 v-if="applicationStatus!=='Policy Issued'"class=" text-center text-black">Proposal Submitted. Policy Issuance is in progress</h4>
        <h4 v-if="applicationStatus=='Policy Issued'"class=" text-center text-black">Thank You for buying the policy</h4>
        <p v-if="applicationStatus=='Policy Issued'" class="text-center">A copy of your policy will be shared in your email id.</p>

        <div class="row justify-content-center mt-4" >
        <div class="col-md-3 col-lg-3 col-sm-12 col-xs-12">
          <div class="headingcards" v-if="applicationStatus=='Policy Issued'">
            <div class="mb-3 d-flex gap-2 justify-content-center" >
              <p  class="alignment" >Policy Number</p>
              <h3  class="alignment" >{{this.policyNo}}</h3>
            </div>
            <button class="btn commonButton" @click="downloadPdf()">Download Policy</button>
        </div>
        </div>
      </div>
        
      </div>
    </div> 
  </template>
  <script>
import ProductDetailsHeader from '../productDetailsHeader/ProductDetailsHeader.vue';


  export default {
    components:{
      ProductDetailsHeader
    },
  data(){
    return{
    policyNo: "",
    journeyId:"",
    policy_status:"",
    applicationStatus:""

  };
},
  mounted(){
    if(sessionStorage.getItem("pay_response")) {
      this.payResponse = JSON.parse(sessionStorage.getItem("pay_response"));
    }
    if(sessionStorage.getItem("policy_status") && sessionStorage.getItem("policy_status") !== "undefined") {
      this.policy_status = JSON.parse(sessionStorage.getItem("policy_status"));
    }
    if(sessionStorage.getItem("journey_id")){
      this.journeyId = JSON.parse(sessionStorage.getItem("journey_id"));
    }
    if(sessionStorage.getItem("policyNo") && sessionStorage.getItem("policyNo") != '') {
        this.policyNo = sessionStorage.getItem("policyNo");
    }
    if(sessionStorage.getItem("applicationStatus")){
      this.applicationStatus=JSON.parse(sessionStorage.getItem("applicationStatus"));
      console.log("this.applicationStatus",this.applicationStatus)

    }
    if(this.applicationStatus=="Policy Issued"){
      this.downloadPolicy();
    }
   
  },
  methods:{
    downloadPolicy() {
      var self = this;
      this.errMsg = "";
      console.log('downloadPolicy called: ');
      this.loading2 = true;
      let headers = {};
      const qData = [this.journeyId];

      this.backendApi("downloadPolicy", qData, headers, "get")
        .then((response) => {
          this.loading2 = false;
         
         
          if (response.status == "error") {

            let msg = [response.data.detail];
            //this.$router.push({ path: '/networkalert', query: { backUrl: 'thankyou-page', msg: msg } });
            this.errMsg = msg;
            
          } if(response?.data?.errors?.length>0) {
            let msg = [];
            /*for(let i=0; i<response?.data?.errors?.length; i++)
            {
              msg.push(response.data.errors[i]["errorDisplayMessage"]);
            } 
            this.errMsg = msg; */
            if(this.policyNo && !this.policyPdf.file) {
              msg['0'] = 'Policy Copy is still not available for Download. Please try after some time.';
            } else if(this.applicationNo && !this.policyNo){
              msg['0']  = 'Policy is still with Underwriting.Please try after some time.';
            }
            this.errMsg = msg;
            
          } else  {
            //console.log('response: ', response.data);
            
            this.policyPdf = response.data.data;
            this.setBtnFlag=true;
          //   if( this.policyPdf.file)
          // {
          //   this.downloadPdf();
          // }
            // if(this.policyPdf.manufacturerTracking.policyNumber) {
            //   this.policyNo = this.policyPdf.manufacturerTracking.policyNumber;
            //   sessionStorage.setItem("policyNo",this.policyPdf.manufacturerTracking.policyNumber);
            //   let qData = {
            //     "proposal_ui_id": this.proposalUiId,
            //     "policy_number": this.policyPdf.manufacturerTracking.policyNumber
            //   };
            //   this.saveApplicationNumber(qData);
            //   this.updateProposalStatus(8);
            // }
          }

        })
        .catch((error) => {
          console.log('error msg block : get policy', error);
          self.loading = false;
          let msg=['We are facing some issue while downloading policy'];
          this.errMsg = msg;
        });
    },
    downloadPdf() {
      const byteCharacters = window.atob(this.policyPdf.file);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: "application/pdf" });

      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = this.policyNo;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
  },

  };
  </script>
  <style lang="scss">
 @import "../../assets/scss/components/Loaders.scss";
  </style>
  
  